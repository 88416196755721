import PropTypes from 'prop-types'
import React from 'react'
import pic01 from '../images/pic01.jpg'
import pic02 from '../images/pic02.jpg'
import pic03 from '../images/pic03.png'

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      > </div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${this.props.article === 'intro' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Intro</h2>
          <span className="image main">
            <img src={pic01} alt="" />
          </span>
          <p>
            I am Ethan from Galway City on the west coast of Ireland. I recently moved back to Edinburgh in September 2020 and I am currently working as a DevOps engineers for a client in the US with a small team to implement all development pipelines, AWS services, K8s and IAC. I have over 8 years customer service experience and 2 years civil engineering experience. In November 2020, I graduated from a postgraduate Software
            Development and Design course with the aim to build upon my passion for technology and to pursue a career with Software bringing  all my experience together.
            <p>
            <p></p>
            I am a committed hard working and motivated person, with good leadership skills, excelling at working in teams as well as the ability to work independently. I am a good
            communicator, with a strong will and passion to enhance and build on the skills I have and to further learn and develop new skill sets and abilities.
            I have gained valuable experience from both an educational perspective and in the variety of roles I have worked in over the last number of years which have broadened
            my experience on both a professional and personal level. I have fulfilled numerous positions within each of my previous jobs and am extremely flexible and adaptable to
            change. I want to develop specialist skills in this field with the right company and to add value, with plenty of room to grow.
            </p>
            <p>
            In 2019 I decided to undertake a postgraduate course in Software Development whilst continuing to work as an engineer on a Watermain Rehabilitation project in Galway. I
            made this decision to pursue a career in computer science as I have always had a passion and interest in technology and computers.
            I relish in the ability to keep my interest in this area and similarly this is one of my values to focus and commit to anything I set my mind to and enjoy the fact that computer science is 
            continuously evolving, and I enjoy the stimulus of constantly learning new skills and am focused on developing further skills in this area.
            </p>
            <p>
            I aim to continually expand my knowledge and personal development. To perform to the best of my abilities no matter what the task or challenge. I value working for those who have the same ethics 
            as me and to strive to ensure that I give 100% commitment in anything I do and trust, loyalty and respect ,striving for excellence are key values for me. I would like to consolidate the experience I 
            have gained and to utilise this to best effect. I am a self starter and like to use my initiative and have very good problem solving and analytical skills.
            </p>
          </p>
         
          {close}
        </article>

        <article
          id="work"
          className={`${this.props.article === 'work' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Work</h2>
          <span className="image main">
            <img src={pic02} alt="" />
          </span>
          <p>
            Since the age of 17 I have always worked throughout my studies. I started off in the retail sector working from Marks and Spencers as a customer assistant, eventually moving into a food stock management
            position. I worked for Marks and Spencers for 6 years whilst undertaking my undergraduate degree. I then worked in the hospitality sector as a bartender in Edinburgh while I was completing my first postgraduate course
            following on from this I began working for Europcar as a customer service assitant. 
            </p> 
           <p>
            I moved back to Ireland in early 2019 and started working as a Junior civil engineer on an Irish water project in Galway city. Once this
            watermain rehabilitation project was complete, I began working on the upcoming street improvement project in Ennis, county Clare. I relocated to Edinburgh in September 2020 and commenced working as a technical support agent
            for 10x Future Technologies, supporting and triaging client developer tickets, along with monitoring all platform environments (PROD, INT, E2E, NFT). Helping to resolve and manage high severity platform incidents, along with
            communcating progress and root cause analysis to the client. The banking platform is cloud native (AWS) offering a suite of microservices, with the goal of streamlining the development process and mitigating risk from the client.
          </p>
          <p>
            I started working as an associate developer for Saggezza in July 2021. Working primarily as a Java back end developer. Saggezza is a consultancy, so I am constantly learning and keeping my skills upto date, whilst upskilling to meet
            the demands of any project. In September I was placed on a upcoming project as a DevOps engineer and I am thouroughly enjoing the challenge of the role with all of it's responsibility and working in a small team to implement all of the development (CI/CD) pipelines, infrastructure and AWS services. It has been great for us to enable the developement and QA teams to efficiently carry out there work without having any bottlenecks.
          </p>
         
          {close}
        </article>

        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Education</h2>
          <span className="image main">
            <img src={pic03} alt="" />
          </span>
          <p>
          Higher Diploma Software Design and Development (NFQ Level 8)
          National University of Ireland, Galway • Galway, Ireland • 2020 • 2.1
          <ul>
          <li> Project - Mobile study application built using React Native </li>
          </ul>
          </p>
          <hr></hr>
          <p>
          Postgraduate Diploma in Climate Change: Impacts and Mitigation (NFQ Level 9)
          Heriot-Watt University • Edinburgh • 2018 
          <ul>
          <li> Dissertation - A review of microalgae as sustainable feedstock for the production of biofuels</li>
          </ul>
          </p>
          <hr></hr>
          <p>
          Bachelor of Science in Earth and Ocean Science (NFQ Level 8)
          National University of Galway, Ireland • Galway, Ireland • 2015 • 2.1
          <ul>
          <li> Dissertation - Investigation of the distribution of Radium 223/224 as tracers of groundwater and mixing in Galway Bay</li>
          </ul>
          </p>
          

          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>
          <form method="post" netlify-honeypot="bot-field" data-netlify="true" name="contact">
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="contact" />
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" className="special" />
              </li>
              <li>
                <input type="reset" value="Reset" />
              </li>
            </ul>
          </form>
          <ul className="icons">
         
            <li>
              <a href="https://www.linkedin.com/in/ethan-kane-1992ek/" className="icon fa-linkedin">
                <span className="label">linkedin</span>
              </a>
            </li>
            <li>
              <a href="https://github.com/kanerz92?tab=repositories" className="icon fa-github">
                <span className="label">GitHub</span>
              </a>
            </li>
          </ul>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
